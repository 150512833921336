.nav-container {
  padding: 1rem 8%;
}

.page-internal .nav-container {
  background: radial-gradient(circle at 50% 0%, #291986, #4a4473);
  color: #fff;
  padding-bottom: 2rem;
}

.page-internal .nav-container button {
  color: #fff;
}

.nav-container a, .nav-container a:visited {
  color: #fff;
}

.nav-logo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 1rem;
  flex-wrap: wrap;
}

.nav-container.compact .nav-logo {
  margin-bottom: 0;
}

.nav-logo a {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-left: -10px;
}

.nav-logo svg {
  max-width: 60px;
  margin-right: 1rem;
}

.logotype {
  transform: rotate(-2deg);
  display: inline-block;
  font-family: "Pacifico", sans-serif;
  font-size: 2.25rem;
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.9);
}

.nav-container.compact .nav-links, .nav-container.compact .nav-right {
  display: none;
}

.page-internal .nav-container.compact {
  padding-bottom: 1.5rem;
}

.nav-links {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}

.nav-links a {
  display: inline-block;
  padding: 1rem;
  font-weight: 700;
}

.nav-links a:hover {
  text-decoration: underline;
}

.nav-links a.active {
  text-decoration: underline;
}

@media(min-width: 1023px) {
  .nav-container.compact .nav-links, .nav-container.compact .nav-right {
    display: flex;
  }
  .nav-container {
    padding: 1rem 4rem;
  }
  .page-internal .nav-container {
    padding-bottom: 1rem;
  }
  .page-internal .nav-container.compact {
    padding-bottom: 1rem;
  }
  .nav-container nav {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .nav-left {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
  }
  .nav-logo {
    margin-bottom: 0;
  }
  .nav-logo a {
    flex-direction: column;
    margin-left: 0;
    margin-right: 1rem;
  }
  .nav-logo svg {
    margin-right: 0;
    max-height: 50px;
  }
  .logotype {
    font-size: 1.75rem;
  }
  .nav-links {
    margin-bottom: .5rem;
    flex-wrap: wrap;
  }
  .nav-links a {
    padding: 1rem;
  }
  .nav-right {
    margin-bottom: .5rem;
  }
}
