.inboxform-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
}

.inboxform-input input {
  flex-grow: 1;
  margin-right: .5rem;
  text-align: right;
}

.inboxform-input span {
  display: inline-block;
  margin-bottom: 3px;
}

.inboxform-container button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.inboxform-container button svg {
  max-width: 18px;
  margin-right: .5rem;
}

@media(min-width: 700px) {
  nav .inboxform-input {
    margin-bottom: 0;
    margin-right: 1.5rem;
  }
  nav .inboxform-input input {
    flex-grow: inherit;
  }
  .inboxform-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
}

@media(min-width: 1023px) {
  .inboxform-input input {
    max-width: 180px;
  }
  .inboxform-container button {
    margin-right: 0;
    /*margin-bottom: 1rem;*/
  }
  .email-section-container .inboxform-container button {
    margin-bottom: 1rem;
    margin-right: 0;
  }
}
