.messagelist-container .messagelist-row-link {
  color: #333;
  padding: 1rem 0;
  display: block;
}

.messagelist-row {
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
}

.messagelist-row:last-of-type {
  border-bottom: 0;
}

.messagelist-from {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.messagelist-date {
  opacity: 0.75;
}

.messagelist-subject {
  text-overflow: ellipsis;
  white-space: normal;
  overflow: hidden;
  color: #c0392b;
  font-weight: 700;
}

.messagelist-subject span {
  display: inline-block;
  border-bottom: solid 1px rgba(231, 76, 60, 0.5);
}

@media(min-width: 700px) {
  .messagelist-container .messagelist-row-link {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .messagelist-container a.messagelist-row-link:hover {
    background-color: rgba(255, 255, 255, 0.95);
  }
  .messagelist-container a.messagelist-row-link:hover .messagelist-subject span {
    border-bottom: solid 1px transparent;
  }
  .messagelist-from {
    flex-basis: 70%;
  }
  .messagelist-date {
    flex-basis: 30%;
    text-align: right;
  }
  .messagelist-subject {
    flex-basis: 100%;
  }
}

@media(min-width: 1023px) {
  .messagelist-container {
    max-width: 800px;
    margin: 0 auto;
  }
}
