.privacy-text-section {
  padding: 0 8% 3rem;
}

.privacy-text-section h2 {
  margin-top: 3rem;
}

.privacy-text-section a {
  border-bottom: solid 1px rgba(231, 76, 60, 0.5);
}

.privacy-text-section a:hover {
  border-bottom: solid 1px transparent;
}

@media(min-width: 700px) {
  .privacy-text-section {
    padding: 1rem 4rem 5rem;
  }
  .privacy-text-section > * {
    margin-left: auto;
    margin-right: auto;
  }
  .privacy-text-section p {
    max-width: 600px;
  }
  .privacy-text-section h2 {
    max-width: 600px;
  }
}
