.home-top-container {
  background: radial-gradient(circle at 50% 0%, #291986, #4a4473);
  color: #fff;
}

.home-banner-container {
  padding: 0 8% 4rem;
}

.home-banner-container h1, .home-banner-container h3 {
  line-height: 1.4;
  text-align: center;
}

.form-container {
  background-color: #4868FC;
  color: #fff;
  padding: 3rem 8%;
}

.form-container p {
  color: rgba(255, 255, 255, 0.75);
}

.form-triangle {
  border-top: 20px solid #464075;
}

.suggestion-container {
  background-color: rgb(72, 110, 253, 0.75);
  color: #fff;
  padding: 3rem 8%;
}

.suggestion-container p {
  color: rgba(255, 255, 255, 0.75);
}

.suggestion-triangle {
  border-top: 20px solid #464075;
}

.suggestion-data {
  font-size: 1.125rem;
  text-align: center;
}

.suggestion-data a {
  font-weight: 700;
  color: #fff;
  border-bottom: solid 1px rgba(255, 255, 255, 0.5);
}

.suggestion-data a:hover {
  border-bottom: 0;
}

.suggestion-button-container button {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.suggestion-button-container button svg {
  fill: #fff;
  max-width: 16px;
  max-height: 16px;
  margin-top: 2px;
  margin-right: .5rem;
}

.idea-section-container {
  background: radial-gradient(circle at 50% 0%, #f6f6f6, #f3f3f3);
}

.idea-container {
  padding: 3rem 8%;
}

.idea-container a {
  font-weight: 700;
}

.idea-container li {
  color: rgba(0, 0, 0, 0.8);
}

.idea-triangle {
  border-top: 20px solid #4868FC;
}

.idea-container ul {
  list-style-type: none;
}

.idea-container li {
  margin-bottom: 1.5rem;
}

.idea-container li:last-child {
  margin-bottom: 0;
}

.idea-container .using {
  color: #c0392b;
}

.idea-container a {
  text-decoration: none;
  border-bottom: solid 1px rgba(231, 76, 60, 0.5);
}

.idea-container a:hover {
  text-decoration: none;
  border-bottom: 0;
}

.antispam-section-container {
  background: radial-gradient(circle at 50% 0%, #7f8c8d, #5f6c6d);
  color: #fff;
}

.antispam-container {
  padding: 3rem 8% 1.5rem;
}

.antispam-container p {
  color: rgba(255, 255, 255, 0.75);
}

.antispam-triangle {
  border-top: 20px solid #f3f3f3;
}

.antispam-container a, .antispam-container a:visited {
  color: #fff;
  font-weight: 700;
  border-bottom: solid 1px rgba(255, 255, 255, 0.5);
}

.antispam-container a:hover {
  border-bottom: 0;
}

.statistics-container {
  padding: 0 8% 3rem;
  text-align: center;
}

.statistics-container h5 {
  margin-bottom: .5rem;
  font-size: 1.125rem;
  line-height: 2.5rem;
  opacity: 0.75;
}

.statistics-number {
  display: inline-block;
  margin-bottom: 1.5rem;
  font-size: 2rem;
  line-height: 2rem;
  font-weight: 300;
  transition: transform .3s;
}

.statistics-number.changed {
  transform: scale(1.3);
}

.statistics-number.loading {
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.5);
}

.statistics-number:last-child {
  margin-bottom: 0;
}

.opensource-section-container {
  background: radial-gradient(circle at 50% 0%, #34495e, #14293e);
  color: #fff;
  overflow: hidden;
}

.opensource-container {
  overflow: hidden;
  padding: 3rem 8%;
}

.opensource-container p {
  color: rgba(255, 255, 255, 0.75);
}

.opensource-container a, .opensource-container a:visited {
  color: #fff;
  font-weight: 700;
  border-bottom: solid 1px rgba(255, 255, 255, 0.5);
}

.opensource-container a:hover {
  border-bottom: 0;
}

.opensource-section-container:before {
  content: 'export async function listHandler(event: APIGatewayProxyEvent): Promise<APIGatewayProxyResult> {\A  const ip = event.requestContext.identity.sourceIp;\A  return ratelimit(ip, client).then(() => {\A    const mailbox = (event.pathParameters || {})["name"];\A    console.log(`client ${ip} requesting mailbox ${mailbox}`);\A    return getInbox(mailbox);\A  }).then((results) => {\A    return {\A      statusCode: 200,\A      headers: {\A        "Access-Control-Allow-Origin": "*",\A        "Access-Control-Allow-Credentials": true,\A      },\A      body: JSON.stringify(results)\A    };\A  }, (reason) => {\A    console.log(`error for ${ip} : ${reason}`);\A    return {\A      statusCode: 500,\A      body: JSON.stringify({error: reason})\A    };\A  });\A}';
  position: absolute;
  text-align: left;
  overflow: hidden;
  display: block;
  left: 4%;
  top: .5rem;
  font-size: .875rem;
  color: rgba(255, 255, 255, 0.1);
  font-family: fixed,monospace;
  white-space: pre;
}

.opensource-triangle {
  border-top: 20px solid #5f6c6d;
}

.sponsor-google-container {
  margin: 0 8% 3rem;
  text-align: center;
}

.page-home button {
  color: #fff;
}

@media(min-width: 700px) {
  .home-banner-container {
    padding: 2rem 4rem 6rem;
  }
  .email-section-container {
    display: flex;
    flex-direction: row;
    align-items: stretch;
  }
  .email-section-container > div {
    flex-basis: 50%;
    flex-grow: 0;
    flex-shrink: 0;
  }
  .form-container {
    padding: 4rem 2.5rem 5rem;
  }
  .form-container p {
    margin-bottom: 3rem;
  }
  .form-container button {
    display: flex;
    margin: 0 auto;
  }
  .suggestion-container {
    padding: 4rem 2.5rem 5rem;
  }
  .triangle.suggestion-triangle {
    left: inherit;
    right: 2.5rem;
    border-top: 20px solid #464075;
  }
  .suggestion-button-container {
    text-align: center;
  }
  .suggestion-button-container button {
    width: auto;
    margin: 0 auto;
  }
  .triangle.idea-triangle {
    border-top: 20px solid #4868FC;
  }
  .idea-container {
    padding: 4rem 2.5rem 5rem;
  }
  .idea-container > h4 {
    text-align: center;
    margin-bottom: 0;
  }
  .idea-container ul {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .idea-container li {
    flex-basis: 29%;
    margin-top: 2.5rem;
    margin-bottom: 0;
  }
  .idea-container li h5 {
    line-height: 1.4;
  }
  .antispam-section-container {
    display: flex;
    flex-direction: row;
    align-items: stretch;
  }
  .antispam-section-container > div {
    flex-basis: 50%;
  }
  .antispam-container {
    padding: 4rem 2.5rem 5rem;
  }
  .statistics-container {
    padding: 4rem 2.5rem 5rem;
  }
  .statistics-number {
    font-size: 2.25rem;
  }
  .opensource-section-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .opensource-section-container > div {
    flex-basis: 50%;
  }
  .opensource-container {
    padding: 4rem 2.5rem 5rem;
    text-align: center;
  }
  .sponsor-google-container {
    margin: 0;
    padding: 4rem 2.5rem 5rem;
    text-align: center;
  }
}

@media(min-width: 1023px) {
  .home-banner-container {
    padding-bottom: 8rem;
  }
  .form-container {
    padding: 5rem 4rem 6rem;
  }
  .form-container button {
    display: flex;
    margin: 0 auto;
  }
  .suggestion-container {
    padding: 5rem 4rem 6rem;
  }
  .triangle.suggestion-triangle {
    right: 4rem;
  }
  .idea-container {
    padding: 5rem 4rem 6rem;
  }
  .antispam-container {
    padding: 5rem 4rem 6rem;
  }
  .statistics-container {
    padding: 5rem 4rem 6rem;
  }
  .opensource-container {
    padding: 5rem 4rem 6rem;
  }
}

.adspot {
  display:none;
}
@media(min-width: 600px) and (max-width: 1135px){ .adspot.home-ad-300 { display: block; } }
@media(min-width: 1136px) { .adspot.home-ad-568 { display:block; } }
