.messagebody-container {
  overflow-x: scroll;
  margin-bottom: 2rem;
}

.messagebody-container img {
  max-width: none;
}

.messagedata-iframe {
  border: 0;
  padding: 0;
  margin: 0;
  width: 100%;
  min-height: 50px;
}

.messagedata-iframe.hidden {
  display: none;
}

.messagesource-container {
  overflow-x: scroll;
  white-space: pre;
  font-family: monospace;
  margin-bottom: 2rem;
  font-size: .875rem;
}

.messageactions-container {
  margin: 1rem 0 2rem 0;
  font-size: .75rem;
}

.messageactions-container button {
  color: #c0392b;
  border: solid 1px #c0392b;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-weight: 700;
}

.messageactions-container button:last-of-type {
  margin-bottom: 0;
}

.messageactions-container button svg {
  max-width: 15px;
  max-height: 15px;
  margin-right: .5rem;
}

.messageactions-container button.delete-button svg {
  fill: #c0392b;
}

.messageactions-container button:active, .messageactions-container button:hover {
  background-color: rgba(231, 76, 60, 0.1);
}

@media(min-width: 700px) {
  .messagedata-container {
    padding: 0 1rem;
  }
  .messageactions-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  .messageactions-container button {
    margin-right: 1rem;
    margin-bottom: 0;
    min-width: 100px;
    width: auto;
  }
  .messageactions-container button:last-of-type {
    margin-right: 0;
  }
}