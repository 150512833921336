.messagetotal-container h5 {
  text-align: center;
  opacity: 0.75;
  margin-bottom: .5rem;
}

@media(min-width: 700px) {
  .messagetotal-container h5 {
    margin-bottom: 0;
  }
}
