.inbox-main-section {
    padding: 1rem 8% 3rem;
}

.inbox-main-section div h1.inbox-title {
    font-size: 1.25rem;
}

.inbox-main-section svg path {
    fill: #c0392b;
}

.inboxheader-container {
    margin-bottom: .75rem;
}

.inboxheader-container button {
    color: #c0392b;
    border: solid 1px #c0392b;
    margin-bottom: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.inboxheader-container button svg {
    max-width: 16px;
    max-height: 16px;
    margin-top: 2px;
    margin-right: .5rem;
}

.inboxheader-container button:active, .inboxheader-container button:hover {
    background-color: rgba(231, 76, 60, 0.1);
}

.inbox-sponsor-section {
    background: radial-gradient(circle at 50% 0%, #7f8c8d, #5f6c6d);
    color: #fff;
}

.altinbox-container {
    padding: 3rem 8% 0;
}

.altinbox {
    font-weight: 700;
}

.altinbox-container p.altinbox {
    color: #ffffff;
}

.altinbox-container p {
    color: rgba(255, 255, 255, 0.75);
}

.sponsor-container {
    padding: 3rem 8%;
}

.sponsor-triangle {
    border-top: 20px solid #f6f6f6;
}

.sponsor-container p {
    color: rgba(255, 255, 255, 0.75);
}

.inbox-sponsor-section .sponsor-container a {
    color: #ffffff;
    font-weight: 700;
    border-bottom: solid 1px rgba(255, 255, 255, 0.5);
}

.sponsor-container a:hover {
    border-bottom: solid 1px transparent;
}

.rss-icon i{
    font-size: 1.5em;
    margin-left: 2em;
}
.inbox-main-section div.inbox-header-div{
display: flex;
justify-content: center;
align-items: center;
}

@media (min-width: 700px) {
    .inbox-main-section {
        padding: 2rem 1.5rem 6rem;
    }

    .inbox-main-section div.inbox-header-div h1.inbox-title {
        font-size: 2rem;
    }

    .inboxheader-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .inboxheader-container button {
        margin-left: 2rem;
        width: auto;
    }

    .inbox-sponsor-section {
        display: flex;
        flex-direction: row;
        align-items: stretch;
    }

    .inbox-sponsor-section > div {
        flex-basis: 50%;
        flex-grow: 0;
        flex-shrink: 0;
    }

    .altinbox-container {
        padding: 4rem 2.5rem 5rem;
    }

    .sponsor-container {
        padding: 4rem 2.5rem 5rem;
    }
}

@media (min-width: 1023px) {
    .inbox-main-section {
        padding: 2rem 4rem 6rem;
    }

    .altinbox-container {
        padding: 5rem 4rem 6rem;
    }

    .sponsor-container {
        padding: 5rem 4rem 6rem;
    }
}


.adspot {
    display: none;
}


@media (min-width: 600px) and (max-width: 1135px) {
    .inbox-ad-300 {
        display: block;
    }
}

@media (min-width: 1136px) {
    .inbox-ad-568 {
        display: block;
    }
}

@media (min-width: 1024px) {
    .inbox-header-ad-728 {
        margin-left: auto;
        margin-right: auto;
        width: 728px;
        display: block;
    }

    .inbox-header-ad-container {
        margin-top: 5px;
    }
}
