@keyframes Loading-anim {
  0% { opacity: 0; }
  50% { opacity: 0.8; }
  100% { opacity: 0; }
}

.Loading {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  animation: Loading-anim 3s infinite ease-in-out;
}

.Loading svg {
  max-width: 32px;
  margin-right: .5rem;
}
