footer {
  background: radial-gradient(circle at 50% 0%, #291986, #4a4473);
  color: #fff;
  font-size: .875rem;
  padding: 2rem 8%;
  text-align: center;
}

.footer-logo {
  max-width: 60px;
  opacity: 0.5;
  margin: 0 auto;
}

footer a, footer a:visited {
  color: #fff;
  font-weight: 700;
  display: inline-block;
  padding: .5rem;
}

footer a:hover {
  text-decoration: underline;
}

footer a.active {
  text-decoration: underline;
}

footer .created {
  color: rgba(255, 255, 255, 0.65);
  font-size: .75rem;
  margin-top: 1.5rem;
}

footer .created a {
  padding: 0;
  font-weight: 700;
  border-bottom: solid 1px rgba(255, 255, 255, 0.5);
}

footer .created a:hover {
  text-decoration: none;
  border-bottom: solid 1px transparent;
}
