.howitworks-text-section {
  padding: 0 8% 3rem;
}

.howitworks-text-section h1 {
  font-style: italic;
  font-weight: bold;
}

.howitworks-text-section h2 {
  margin-top: 3rem;
  font-weight: bold;
}

.howitworks-text-section ol li {
  margin-top: 1rem;
}

@media(min-width: 700px) {
  .howitworks-text-section {
    padding: 1rem 4rem 5rem;
  }
  .howitworks-text-section > * {
    margin-left: auto;
    margin-right: auto;
  }
  .howitworks-text-section p {
    max-width: 600px;
  }
  .howitworks-text-section li {
    max-width: 600px;
  }
  .howitworks-text-section h2 {
    max-width: 600px;
  }
}
