.Error {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  transform: translateY(200%);
  transition: transform .2s ease-in-out;
  padding: 1rem 8%;
  z-index: 999;
  /*background: radial-gradient(circle at 50% 0%, #f8f8f8, #f3f3f3);*/
  background: radial-gradient(circle at 50% 0%, #34495e, #14293e);
  color: #fff;
  text-align: center;
  border-top: solid 1px rgba(0, 0, 0, 0.3);
  font-weight: 700;
}

.Error.visible {
  transform: translateY(0);
}

@media(min-width: 700px) {
  .Error {
    padding: 2rem 2.5rem;
  }
}
